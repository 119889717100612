.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #3e497a;
  }
  
  .about {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #21325e;
    color: #f0f0f0;
  }
  
 
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
    cursor: pointer;
  }
  


  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .skills h1 {
    color: #3e497a;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
  }
  
  .list span {
    font-size: 20px;
  }
  
 

  @media only screen and (max-width: 755px) {
    h1{
        font-size: 50px;
    }
    
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }